<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card title="商户信息" :bordered='false' style="margin-bottom: 20px;">

      <a-descriptions style="margin-bottom: 30px" :column="4">
        <a-descriptions-item label='订单号'>{{ dashboard.id }}</a-descriptions-item>
        <a-descriptions-item label='下单时间'>{{dashboard.createTime}}</a-descriptions-item>
        <a-descriptions-item label='购买人'>{{ dashboard.nickname }}</a-descriptions-item>
        <a-descriptions-item label='购买人手机号'>{{dashboard.drawMobile}}</a-descriptions-item>

<!--        <a-descriptions-item label='下单方式'>{{dashboard.paymentMethod}} </a-descriptions-item>-->
<!--        <a-descriptions-item label=''></a-descriptions-item>-->

        <a-descriptions-item label='订单金额'>￥{{dashboard.amount}}</a-descriptions-item>
        <a-descriptions-item label='用户应付'>￥{{dashboard.amount}}</a-descriptions-item>
        <a-descriptions-item label='用户积分抵扣'>-{{dashboard.memberDiscount}}</a-descriptions-item>
<!--        <a-descriptions-item label='用户实付'>￥{{dashboard.merchantAmount}} </a-descriptions-item>-->
        <a-descriptions-item label='用户实付'>￥{{dashboard.consumeAmount}} </a-descriptions-item>

        <a-descriptions-item label='用户支付方式'>{{dashboard.paymentMethodName}}</a-descriptions-item>
        <a-descriptions-item ></a-descriptions-item>
<!--        <a-descriptions-item label='所属商户编码'>-->
<!--          <span v-for="(item, index) in getAuthCodeList(dashboard.authCode)" :key="index"-->
<!--                :style="{color: index == 1 ? '#1890ff' : ''}">{{ item }}-->
<!--          </span>-->
<!--        </a-descriptions-item>-->
        <a-descriptions-item label='负责人'>{{ dashboard.masterName }}</a-descriptions-item>
        <a-descriptions-item label='下单方式'>{{dashboard.operatorNickname}}</a-descriptions-item>

        <template  v-if="!dashboard.isShowOne">
          <a-descriptions-item label='商户原始应付'>￥{{dashboard.firstMerchantAmount}}</a-descriptions-item>
          <a-descriptions-item label='商户应付'>￥{{dashboard.originalMerchantAmount}}</a-descriptions-item>
          <a-descriptions-item label='商户积分抵扣'>-{{dashboard.merchantDiscount}}</a-descriptions-item>
          <a-descriptions-item label='商户实付'>￥{{dashboard.merchantAmount}} </a-descriptions-item>

          <a-descriptions-item label='商户支付方式'>{{dashboard.merchantPaymentMethodName}}</a-descriptions-item>
          <a-descriptions-item label='商户支付时间'>{{dashboard.merchantPaymentTime}} </a-descriptions-item>
          <a-descriptions-item label=''></a-descriptions-item>
          <a-descriptions-item label=''></a-descriptions-item>
        </template>
      </a-descriptions>

    </a-card>

    <a-card title="积分奖励" :bordered='false' v-if="dashboard.auditStatus!=1">

      <a-descriptions style="margin-bottom: 30px" :column="2">
        <template>
          <template  v-for="(item, index) in dashboard.integralRewardList" >
            <a-descriptions-item  :key="index" >{{item.remark}}:   {{item.memberName||' 未知 :'}} </a-descriptions-item>
            <a-descriptions-item  :key="index" label='订单积分'>:   {{item.value}} </a-descriptions-item>
<!--            <a-descriptions-item  :key="index" label='可用积分'>:   {{Math.round(item.value*0.85)}} </a-descriptions-item>-->
<!--            <a-descriptions-item  :key="index" label='兑换积分'>:   {{item.value-Math.round(item.value*0.85)}} </a-descriptions-item>-->
          </template>
        </template>
      </a-descriptions>

    </a-card>


  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {getMemberOrderDetail, queryCashDetail} from "@/api/cash";


export default {
  name: 'ProposalEdit',

  data() {
    return {
      loading: false,
      profile: {},
      goodImagesList: [],
      badImagesList: [],
      usedImagesList: [],
      content: "",
      previewVisible: false,
      previewImage: "",
      dashboard: {}, //统计数据
      order: {},
    }
  },
  components: { STable, Ellipsis },
  created() {
    this.handleInitial()
  },
  activated() {
    this.handleInitial();
  },
  methods: {
    handleInitial() {
      this.id = this.$route.query.id;
      let params = {
        id: this.id
      }
      queryCashDetail(params).then(result => {
        let backRecord = result.operatorNickname=='后台录单';
        if(backRecord){
          result.isShowOne = true;
        }

        this.dashboard = result;
        this.loading = false;
      })
    },
    getAuthCodeList(authCode) {
      // debugger;
      if (authCode) {
        let index = authCode.indexOf("-");
        if (index < 0) {
          index = authCode.length - 1;
        } else {
          index--;
        }
        let ownAuthCode = authCode.slice(index - 3, index + 1);
        if (ownAuthCode) {
          const authCodeList = authCode.split(ownAuthCode);
          authCodeList.splice(1, 0, ownAuthCode);
          return authCodeList;
        }
      }
      return [];
    },
  }
}
</script>
<style lang="less" scoped>
.image_list {
  width: 150px;
  height: 150px;
  margin: 0 20px 20px 0;
  object-fit: cover;
  position: relative;
  .image_detail {
    height: 100%;
    width: 100%;
  }
  .play_image {
    position: absolute;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
}
</style>
